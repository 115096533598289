<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 265px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">会社・部門方針<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'Dashboard' }"
                >ダッシュボード</router-link
              >
            </li>
            <li>
              <span>会社・部門方針編集</span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="action_wrap m_b5" v-if="commonValidationError">
        <p class="s_err">
          入力内容に不備があります。<span
            ><i class="fas fa-times msg_remove"></i
          ></span>
        </p>
      </div>

      <div
        class="modal"
        id="discardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form_outwrap">
                  <div class="modal_header">
                    <p class="ttl_header">確認</p>
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <p>
                          <span
                            >入力途中の内容は削除されますがよろしいですか？</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          name=""
                          value="キャンセル"
                          class="close"
                          dusk="delete_data"
                          @click="closeDiscard()"
                        >
                          キャンセル
                        </button>
                      </div>
                      <div class="btn_norm delete">
                        <button
                          @click="discardModal()"
                          class="btn btn-primary cln_form_btn"
                          value="アーカイブ"
                          title="削除"
                          dusk="save"
                        >
                          実行
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn"
            class="pop-remove_btn ttoltip tip-top close"
            @click="closeDiscard()"
          >
            <i class="fas fa-times"></i>
          </p>
          <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
        </div>
        <!--modal_inner-->
      </div>
      <!--modal-->
      <!-- <Spinner v-if="spinner" /> -->
      <!-- Vee-validate form field -->
      <Form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="update(editmessage.id)"
        class="createform"
      >
        <div class="command_wrap command_bottom">
          <!--leftarea-->
          <div class="rightarea">
            <div>
              <div class="btn_norm">
                <button type="submit" dusk="com_save_button">保存</button>
              </div>
            </div>
            <div class="commandtext_wrap">
              <div class="commandtextlist"></div>
            </div>
            <!--commandtext_wrap-->
          </div>
          <!--rightarea-->
        </div>
        <!--command_wrap-->

        <div class="contentout_wrap">
          <div class="content_wrap twocolumn">
            <div class="box_wrap left">
              <h2 class="ttl_2">会社方針</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr style="display: none;">
                        <th class="wid_200px">タイトル</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="title"
                                  type="text"
                                  class="form-control"
                                  placeholder="タイトルを入力してください"
                                  v-model="editmessage.title"
                                  id="title"
                                  :rules="isRequired"
                                />
                                <ErrorMessage class="each_msg" name="title" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="editmessage.content"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="editmessage.content"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr id="attached_area00">
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc00">
                            <div
                              v-for="file in editmessage.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                  @click="delete_file(file.id,false)"
                                    class="
                                      item_doc_remove_btn item_remove_btn
                                      tooltip
                                      tip-left
                                    "
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn00" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <div class="box_wrap right">
              <h2 class="ttl_2">公開情報</h2>
              <div class="form_outwrap">
                <div class="wrap wid_100per p_r0">
                  <div><p class="ttl_3">公開ステータス</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        class="form-control"
                        v-model="editmessage.status"
                        :rules="isRequired"
                      >
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="status" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0" style="display: none">
                  <div><p class="ttl_3">カテゴリ</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        name="type"
                        as="select"
                        class="form-control"
                        v-model="editmessage.type"
                      >
                        <option value="news">お知らせ</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="type" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap p_r0">
                  <p class="ttl_3">公開日</p>
                  <div class="in_wrap">
                    <Field
                      name="published_at"
                      type="datetime-local"
                      class="form-control"
                      v-model="editmessage.published_at"
                    />
                    <ErrorMessage class="each_msg" name="published_at" />
                    <!--datepickbox-->
                  </div>
                  <!--in_wrap-->
                </div>
                <!--wrap-->
              </div>
              <!--form_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap left">
              <h2 class="ttl_2">CS営業部</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="cs_message.value"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="cs_message.value"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr id="attached_area01">
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc01">
                            <div
                              v-for="file in cs_message.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                  @click="delete_file(file.id,'cs')"
                                    class="
                                      item_doc_remove_btn item_remove_btn
                                      tooltip
                                      tip-left
                                    "
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          <!--row_form_wrap-->
                          </div>
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn01" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap left">
              <h2 class="ttl_2">エリアサービス部</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="areaService_message.value"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="areaService_message.value"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr id="attached_area02">
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc02">
                            <div
                              v-for="file in areaService_message.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                  @click="delete_file(file.id,'areaService')"
                                    class="
                                      item_doc_remove_btn item_remove_btn
                                      tooltip
                                      tip-left
                                    "
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn02" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap left">
              <h2 class="ttl_2">環境サービス部</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="environmentService_message.value"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="environmentService_message.value"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr id="attached_area03">
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc03">
                            <div
                              v-for="file in environmentService_message.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                  @click="delete_file(file.id,'environmentService')"
                                    class="
                                      item_doc_remove_btn item_remove_btn
                                      tooltip
                                      tip-left
                                    "
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn03" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap left">
              <h2 class="ttl_2">リサイクル部</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="recycling_message.value"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="recycling_message.value"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr id="attached_area04">
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc04">
                            <div
                              v-for="file in recycling_message.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                  @click="delete_file(file.id,'recycling')"
                                    class="
                                      item_doc_remove_btn item_remove_btn
                                      tooltip
                                      tip-left
                                    "
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn04" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap left">
              <h2 class="ttl_2">コントロール部</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="control_message.value"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="control_message.value"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr id="attached_area05">
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc05">
                            <div
                              v-for="file in control_message.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                  @click="delete_file(file.id,'control')"
                                    class="
                                      item_doc_remove_btn item_remove_btn
                                      tooltip
                                      tip-left
                                    "
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn05" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap left">
              <h2 class="ttl_2">内部統括部</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  v-model="internalControl_message.value"
                                  :config="editorConfig"
                                  @input="onEditorInput"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  v-model="internalControl_message.value"
                                  id="content"
                                  :rules="isRequiredContent"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>
                      <tr id="attached_area06">
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">アップロードファイル（PDF形式）</p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc06">
                            <div
                              v-for="file in internalControl_message.attached_file"
                              :key="file.id"
                              class="row_wrap sort_item input-file attached_wrap"
                            >
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <input
                                        name="file_meta_value[]"
                                        type="text"
                                        :value="file.display_name"
                                        class="f_500 updatedFile fileName"
                                        :data-id="file.id"
                                        placeholder="新規開校○○スクールのご案内"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        :href="file.url"
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <span
                                  @click="delete_file(file.id,'internalControl')"
                                    class="
                                      item_doc_remove_btn item_remove_btn
                                      tooltip
                                      tip-left
                                    "
                                    title="削除"
                                    dusk="remove_shiryo"
                                    ><i class="fas fa-times"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn06" dusk="zairyo_add"
                              ><i class="material-icons"></i>関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
          </div>
          <!--content_wrap-->
        </div>
        <!--contentoutwrap-->
      </Form>
    </div>
    <!--maincontentinner-->
    <template id="item_doc_Template">
      <div class="row_wrap new_item sort_item attached_wrap">
        <div class="col">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <input
                  type="hidden"
                  name="jdg_val[]"
                  class="fileHiddenName"
                  value="new"
                />
                <input
                  name="rel_doc_title[]"
                  type="text"
                  class="fileName uploadFileName"
                  required
                  placeholder="例：1月の営業スケジュールについて"
                  value=""
                />
              </div>
              <div class="fileDisplayName"></div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="col item_list">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <div class="filegroup">
                  <input
                    type="file"
                    name="rel_doc[]"
                    class="input-file"
                    style="display: none"
                    accept=".pdf"
                    required
                  />
                  <label class="filelabel fileup-btn">ファイルを選択</label>
                </div>
              </div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="icon col wid_50px t_c">
          <div class="remove_wrap">
            <a
              href=""
              class="item_remove_btn remove_btn tooltip tip-left delete_task_link"
              title="削除"
              ><i class="fas fa-times"></i
            ></a>
          </div>
        </div>
      </div>
    </template>
  </div>
  <template id="item_doc_Template">
    <div class="attached_wrap new_item">
      <div class="attached_name_btn">
        <input
          name="rel_doc_title[]"
          type="text"
          class="fileName form-control"
          required
          placeholder="例：今月のスケジュールについて"
        />
      </div>
      <div class="attached_fire_btn">
        <label>
          <input
            type="file"
            name="rel_doc[]"
            class="input-file form-control"
            accept=".pdf"
          />
          <span class="file_name">添付ファイル</span>
        </label>
      </div>
      <div class="attached_action_btn">
        <div class="add_task_link task_link_btn">
          <i class="fas fa-plus"></i>
        </div>
        <div class="delete_task_link task_link_btn" tabindex="-1">
          <i class="fas fa-minus"></i>
        </div>
      </div>
      <div class="fileDisplayName"></div>
    </div>
  </template>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import $ from "jquery";
import message from "@/apis/Message";
import user from "@/apis/User";
import AdminPageType from "@/const/AdminPageType.json";
//import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import UploadAdapterPlugin from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";

export default {
  name: "message",
  data() {
    return {
      userlists: "",
      taglists: "",
      messages: [],
      editmessage: {
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        // target_type:"users",
        type: 'policy',
        content_type: "HTML",
        thanks_type: [],
      },

      cs_id: "",
      cs_attached_file:[],
      cs_message: {
        key: "policy_group",
      },
      cs_delete_attached_file: [],

      areaService_id: "",
      areaService_attached_file:[],
      areaService_message: {
        key: "policy_group",
      },
      areaService_delete_attached_file: [],

      environmentService_id: "",
      environmentService_attached_file:[],
      environmentService_message: {
        key: "policy_group",
      },
      environmentService_delete_attached_file: [],

      recycling_id: "",
      recycling_attached_file:[],
      recycling_message: {
        key: "policy_group",
      },
      recycling_delete_attached_file: [],

      control_id: "",
      control_attached_file:[],
      control_message: {
        key: "policy_group",
      },
      control_delete_attached_file: [],

      internalControl_id: "",
      internalControl_attached_file:[],
      internalControl_message: {
        key: "policy_group",
      },
      internalControl_delete_attached_file: [],

      processing: false,
      AdminPageType() {
        return AdminPageType;
      },
      adminpagetype: {},
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ParagraphPlugin,
          CKFinder,
          Base64UploadAdapter,
          ImagePlugin,
          UploadAdapterPlugin,
          SourceEditing,
          Heading,
          Indent,
          ListStyle,
          ImageInsert,
          BlockQuote,
          Table,
          TableToolbar,
          MediaEmbed,
        ],
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
          ],
        },

        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "uploadImage",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo",
            "sourceEditing",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        ckfinder: {
          options: {
            resourceType: "Images",
          },
        },
      },
      editorValidation: true,
      old_fileid: "",
      before_thanks_type: [],
      attached_file: [],
      delete_attached_file: [],
      policy_delete_attached_file: [],
      commonValidationError: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    console.log(ClassicEditor);

    this.message_type = 'policy';
    this.adminpagetype = AdminPageType[this.message_type];
    this.GetPageId();
    this.GetGroupId();
    message
      .getcompany("policy")
      .then((response) => {
        if (response != null) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    let vueThis = this;
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      var $filesSelectName = $el
        .closest(".attached_wrap,.new_item")
        .find(".file_name");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");

      $($filesSelectName).text(e.target.files[0].name);

      if ($fileName.val() == "") {
        if (e.target.files[0].name) {
          $fileName.val(e.target.files[0].name);
        } else {
          var today = new Date();
          $fileName.val(today + e.target.files[0].type);
          // $($fileDisplayName).html("資料名を入力してください");
          // $($fileDisplayName).addClass('each_msg')
        }
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("click", ".item_doc_add_btn01", function (e) {
      var count = 2;
      var $target = $("#itemlist_news");
      var $el = $(e.currentTarget);
      var $itembox = $("#itembox_doc01");
      var htmlString = $("#item_doc_Template").html();
      console.log($itembox);

      $itembox.append(htmlString);

      $("li.new_item,div.new_item").each(function (i) {
        $(this)
          .find("input[type=file]")
          .attr("id", "file" + (i + 1));
        $(this)
          .find(".fileup-btn")
          .attr("for", "file" + (i + 1));
        //$(this).find(".filename").attr('class','js-file' + (i+1));
      });
    });
    $(document).on("click", ".item_doc_add_btn02", function (e) {
      var count = 2;
      var $target = $("#itemlist_news");
      var $el = $(e.currentTarget);
      var $itembox = $("#itembox_doc02");
      var htmlString = $("#item_doc_Template").html();
      console.log($itembox);

      $itembox.append(htmlString);

      $("li.new_item,div.new_item").each(function (i) {
        $(this)
          .find("input[type=file]")
          .attr("id", "file" + (i + 1));
        $(this)
          .find(".fileup-btn")
          .attr("for", "file" + (i + 1));
        //$(this).find(".filename").attr('class','js-file' + (i+1));
      });
    });
    $(document).on("click", ".item_doc_add_btn03", function (e) {
      var count = 2;
      var $target = $("#itemlist_news");
      var $el = $(e.currentTarget);
      var $itembox = $("#itembox_doc03");
      var htmlString = $("#item_doc_Template").html();
      console.log($itembox);

      $itembox.append(htmlString);

      $("li.new_item,div.new_item").each(function (i) {
        $(this)
          .find("input[type=file]")
          .attr("id", "file" + (i + 1));
        $(this)
          .find(".fileup-btn")
          .attr("for", "file" + (i + 1));
        //$(this).find(".filename").attr('class','js-file' + (i+1));
      });
    });
    $(document).on("click", ".item_doc_add_btn04", function (e) {
      var count = 2;
      var $target = $("#itemlist_news");
      var $el = $(e.currentTarget);
      var $itembox = $("#itembox_doc04");
      var htmlString = $("#item_doc_Template").html();
      console.log($itembox);

      $itembox.append(htmlString);

      $("li.new_item,div.new_item").each(function (i) {
        $(this)
          .find("input[type=file]")
          .attr("id", "file" + (i + 1));
        $(this)
          .find(".fileup-btn")
          .attr("for", "file" + (i + 1));
        //$(this).find(".filename").attr('class','js-file' + (i+1));
      });
    });
    $(document).on("click", ".item_doc_add_btn05", function (e) {
      var count = 2;
      var $target = $("#itemlist_news");
      var $el = $(e.currentTarget);
      var $itembox = $("#itembox_doc05");
      var htmlString = $("#item_doc_Template").html();
      console.log($itembox);

      $itembox.append(htmlString);

      $("li.new_item,div.new_item").each(function (i) {
        $(this)
          .find("input[type=file]")
          .attr("id", "file" + (i + 1));
        $(this)
          .find(".fileup-btn")
          .attr("for", "file" + (i + 1));
        //$(this).find(".filename").attr('class','js-file' + (i+1));
      });
    });
    $(document).on("click", ".item_doc_add_btn06", function (e) {
      var count = 2;
      var $target = $("#itemlist_news");
      var $el = $(e.currentTarget);
      var $itembox = $("#itembox_doc06");
      var htmlString = $("#item_doc_Template").html();
      console.log($itembox);

      $itembox.append(htmlString);

      $("li.new_item,div.new_item").each(function (i) {
        $(this)
          .find("input[type=file]")
          .attr("id", "file" + (i + 1));
        $(this)
          .find(".fileup-btn")
          .attr("for", "file" + (i + 1));
        //$(this).find(".filename").attr('class','js-file' + (i+1));
      });
    });
    $(document).on("click", ".item_doc_add_btn00", function (e) {
      var count = 2;
      var $target = $("#itemlist_news");
      var $el = $(e.currentTarget);
      var $itembox = $("#itembox_doc00");
      var htmlString = $("#item_doc_Template").html();
      console.log($itembox);

      $itembox.append(htmlString);

      $("li.new_item,div.new_item").each(function (i) {
        $(this)
          .find("input[type=file]")
          .attr("id", "file" + (i + 1));
        $(this)
          .find(".fileup-btn")
          .attr("for", "file" + (i + 1));
        //$(this).find(".filename").attr('class','js-file' + (i+1));
      });
    });

    $(document).on("change", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      var $fileData = $el
        .closest(".attached_wrap,.new_item")
        .find(".input-file");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");
      if ($el.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });
    $(document).on("click", ".add_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $itembox = $el.closest(".attached_wrap,.new_item");
      var htmlString = $("#item_doc_Template").html();
      $itembox.after(htmlString);
    });
    $(document).on("click", ".delete_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".attached_wrap,.new_item");
      $row.remove();

      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");

      for (var i = 0; i < vueThis.attached_file.length; i++) {
        if (vueThis.attached_file[i].display_name == $fileName.val()) {
          vueThis.attached_file.splice(i, 1);
          break;
        }
      }
      return false;
    });
    $(document).on("click", ".item_doc_remove_btn", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".attached_wrap");
      $row.remove();
    });
    $(document).on("blur", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      $el[0].dataset.value = $el.val();
    });
  },
  methods: {
    GetGroupId() {
      message.policy_show('CS営業部')
        .then((response) => {
          if (response != null) {
            console.log('CS営業部');
            console.log(response.data.data);
            this.cs_message = response.data.data[0];
            this.cs_id = response.data.data[0].id;
            console.log(this.cs_id);
            message.show_policy_file(this.cs_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.cs_message.attached_file = response.data.data;
                console.log(this.cs_message.attached_file);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
            });
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('エリアサービス部')
        .then((response) => {
          if (response != null) {
            console.log('エリアサービス部');
            console.log(response.data.data);
            this.areaService_message = response.data.data[0];
            this.areaService_id = response.data.data[0].id;
            console.log(this.areaService_id);
            message.show_policy_file(this.areaService_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.areaService_message.attached_file = response.data.data;
                console.log(this.areaService_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('環境サービス部')
        .then((response) => {
          if (response != null) {
            console.log('環境サービス部');
            console.log(response.data.data);
            this.environmentService_message = response.data.data[0];
            this.environmentService_id = response.data.data[0].id;
            console.log(this.environmentService_id);
            message.show_policy_file(this.environmentService_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.environmentService_message.attached_file = response.data.data;
                console.log(this.environmentService_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('リサイクル部')
        .then((response) => {
          if (response != null) {
            console.log('リサイクル部');
            console.log(response.data.data);
            this.recycling_message = response.data.data[0];
            this.recycling_id = response.data.data[0].id;
            console.log(this.recycling_id);
            message.show_policy_file(this.recycling_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.recycling_message.attached_file = response.data.data;
                console.log(this.recycling_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('コントロール部')
        .then((response) => {
          if (response != null) {
            console.log('コントロール部');
            console.log(response.data.data);
            this.control_message = response.data.data[0];
            this.control_id = response.data.data[0].id;
            console.log(this.control_id);
            message.show_policy_file(this.control_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.control_message.attached_file = response.data.data;
                console.log(this.control_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('内部統括部')
        .then((response) => {
          if (response != null) {
            console.log('内部統括部');
            console.log(response.data.data);
            this.internalControl_message = response.data.data[0];
            this.internalControl_id = response.data.data[0].id;
            console.log(this.internalControl_id);
            message.show_policy_file(this.internalControl_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.internalControl_message.attached_file = response.data.data;
                console.log(this.internalControl_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
    },
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    GetPageId() {
      message
        .list(this.message_type)
        .then((response) => {
          if (response != null) {
            console.log(response);
            this.message_id = response.data.data[0].id;
            this.editmessageView(this.message_id);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    isRequired(value) {
      if (value) {
        return true;
      }
      return "必須です";
    },
    isRequiredContent(value) {
      if (value || $(".ck-source-editing-area").data("value")) {
        return true;
      }
      return "必須です";
    },
    isRequiredArr(value) {
      if (value.length != 0) {
        return true;
      }
      return "必須です";
    },
    editmessageView(id) {
      this.loader = true;
      message
        .edit(id)
        .then((response) => {
          if (response != null) {
            this.editmessage = response.data.data;
            this.message_type = 'policy';
            console.log("edit");
            this.editmessage.published_at = this.format_date(
              this.editmessage.published_at
            );

            this.editmessage.published_at =
              this.editmessage.published_at.replace(" ", "T");

            message.showfile(id,'key','attached_file')
            .then((response) => {
              if (response.data.data.length != 0) {
                this.editmessage.attached_file = response.data.data;
                console.log(this.editmessage.attached_file);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    update(id) {
      let dataFromSourceEditing = $(".ck-source-editing-area").data("value");
      console.log(dataFromSourceEditing);

      if (dataFromSourceEditing) {
        this.editmessage.content = dataFromSourceEditing;
      }

      this.processing = true;
      setTimeout(this.enable, 3000);

      this.loader = true;
      if (this.editmessage.published_at) {
        this.editmessage.published_at = this.editmessage.published_at.replace(
          "T",
          " "
        );
        this.editmessage.published_at += ":00";
      }
      if($("#attached_area00 .attached_wrap").length > 0){
        for (var i=0; i<$("#attached_area00 .attached_wrap").length; i++) {
          let $el = $("#attached_area00 .attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");
          if($fileData[0] != undefined){
            console.log($("#attached_0rea00 .attached_wrap").length);
            console.log($fileData[0]);
            if(!($fileName.val() == "" && $fileData[0].files.length == 0)){
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.attached_file.push(fileObject);

              var file = $fileData[0].files[0];
              var permit_type = ["application/pdf"];
            }
          }else{
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = '';
            if($fileName_change != null){
              put_file = {
                "key":"attached_file",
                "display_name":$fileName_change,
              }
              
            }else{
              put_file = {
                "key":"attached_file",
                "display_name":$fileName.val(),
              }
            }
            console.log(put_file);
            this.putMessageAttachedfile(put_file,id,$fileId);
          } 
        }
      }
      console.log(this.attached_file);
      if($("#attached_area01 .attached_wrap").length > 0){
        for (i=0; i<$("#attached_area01 .attached_wrap").length; i++) {
          let $el = $("#attached_area01 .attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");
          if($fileData[0] != undefined){
            console.log($("#attached_area01 .attached_wrap").length);
            console.log($fileData[0]);
            if(!($fileName.val() == "" && $fileData[0].files.length == 0)){
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.cs_attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.cs_attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.cs_attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.cs_attached_file.push(fileObject);

              file = $fileData[0].files[0];
              permit_type = ["application/pdf"];
            }
          }else{
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = '';
            if($fileName_change != null){
              put_file = {
                "key":"attached_file",
                "display_name":$fileName_change,
              }
              
            }else{
              put_file = {
                "key":"attached_file",
                "display_name":$fileName.val(),
              }
            }
            console.log(put_file);
            this.policy_putMessageAttachedfile(put_file,this.cs_id,$fileId);
          } 
        }
      }
      console.log(this.cs_attached_file);
      if($("#attached_area02 .attached_wrap").length > 0){
        for (i=0; i<$("#attached_area02 .attached_wrap").length; i++) {
          let $el = $("#attached_area02 .attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");
          if($fileData[0] != undefined){
            console.log($("#attached_area02 .attached_wrap").length);
            console.log($fileData[0]);
            if(!($fileName.val() == "" && $fileData[0].files.length == 0)){
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.areaService_attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.areaService_attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.areaService_attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.areaService_attached_file.push(fileObject);

              file = $fileData[0].files[0];
              permit_type = ["application/pdf"];
            }
          }else{
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = '';
            if($fileName_change != null){
              put_file = {
                "key":"attached_file",
                "display_name":$fileName_change,
              }
              
            }else{
              put_file = {
                "key":"attached_file",
                "display_name":$fileName.val(),
              }
            }
            console.log(put_file);
            this.policy_putMessageAttachedfile(put_file,this.areaService_id,$fileId);
          } 
        }
      }
      console.log(this.areaService_attached_file);
      if($("#attached_area03 .attached_wrap").length > 0){
        for (i=0; i<$("#attached_area03 .attached_wrap").length; i++) {
          let $el = $("#attached_area03 .attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");
          if($fileData[0] != undefined){
            console.log($("#attached_area03 .attached_wrap").length);
            console.log($fileData[0]);
            if(!($fileName.val() == "" && $fileData[0].files.length == 0)){
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.environmentService_attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.environmentService_attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.environmentService_attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.environmentService_attached_file.push(fileObject);

              file = $fileData[0].files[0];
              permit_type = ["application/pdf"];
            }
          }else{
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = '';
            if($fileName_change != null){
              put_file = {
                "key":"attached_file",
                "display_name":$fileName_change,
              }
              
            }else{
              put_file = {
                "key":"attached_file",
                "display_name":$fileName.val(),
              }
            }
            console.log(put_file);
            this.policy_putMessageAttachedfile(put_file,this.environmentService_id,$fileId);
          } 
        }
      }
      console.log(this.environmentService_attached_file);
      if($("#attached_area04 .attached_wrap").length > 0){
        for (i=0; i<$("#attached_area04 .attached_wrap").length; i++) {
          let $el = $("#attached_area04 .attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");
          if($fileData[0] != undefined){
            console.log($("#attached_area04 .attached_wrap").length);
            console.log($fileData[0]);
            if(!($fileName.val() == "" && $fileData[0].files.length == 0)){
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.recycling_attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.recycling_attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.recycling_attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.recycling_attached_file.push(fileObject);

              file = $fileData[0].files[0];
              permit_type = ["application/pdf"];
            }
          }else{
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = '';
            if($fileName_change != null){
              put_file = {
                "key":"attached_file",
                "display_name":$fileName_change,
              }
              
            }else{
              put_file = {
                "key":"attached_file",
                "display_name":$fileName.val(),
              }
            }
            console.log(put_file);
            this.policy_putMessageAttachedfile(put_file,this.recycling_id,$fileId);
          } 
        }
      }
      console.log(this.recycling_attached_file);
      if($("#attached_area05 .attached_wrap").length > 0){
        for (i=0; i<$("#attached_area05 .attached_wrap").length; i++) {
          let $el = $("#attached_area05 .attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");
          if($fileData[0] != undefined){
            console.log($("#attached_area05 .attached_wrap").length);
            console.log($fileData[0]);
            if(!($fileName.val() == "" && $fileData[0].files.length == 0)){
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.control_attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.control_attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.control_attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.control_attached_file.push(fileObject);

              file = $fileData[0].files[0];
              permit_type = ["application/pdf"];
            }
          }else{
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = '';
            if($fileName_change != null){
              put_file = {
                "key":"attached_file",
                "display_name":$fileName_change,
              }
              
            }else{
              put_file = {
                "key":"attached_file",
                "display_name":$fileName.val(),
              }
            }
            console.log(put_file);
            this.policy_putMessageAttachedfile(put_file,this.control_id,$fileId);
          } 
        }
      }
      console.log(this.control_attached_file);
      if($("#attached_area06 .attached_wrap").length > 0){
        for (i=0; i<$("#attached_area06 .attached_wrap").length; i++) {
          let $el = $("#attached_area06 .attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");
          if($fileData[0] != undefined){
            console.log($("#attached_area06 .attached_wrap").length);
            console.log($fileData[0]);
            if(!($fileName.val() == "" && $fileData[0].files.length == 0)){
              if ($fileName.val() == "") {
                alert("資料名を入力してください");
                this.internalControl_attached_file = [];
                return;
              }
              if ($fileData[0].files.length == 0) {
                alert("ファイルを選択してください");
                this.internalControl_attached_file = [];
                return;
              }
              if (file && permit_type.indexOf(file.type) == -1) {
                alert("アップロードできるファイルの形式はpdfです");
                this.internalControl_attached_file = [];
                return;
              }

              let fileObject = new FormData();

              fileObject.append("key", "attached_file");
              fileObject.append("display_name", $fileName.val());
              fileObject.append("uploadfile", $fileData[0].files[0]);

              this.internalControl_attached_file.push(fileObject);

              file = $fileData[0].files[0];
              permit_type = ["application/pdf"];
            }
          }else{
            console.log($fileName);
            let $fileId = $el.find(".fileName").attr("data-id");
            let $fileName_change = $el.find(".fileName").attr("data-value");
            let put_file = '';
            if($fileName_change != null){
              put_file = {
                "key":"attached_file",
                "display_name":$fileName_change,
              }
              
            }else{
              put_file = {
                "key":"attached_file",
                "display_name":$fileName.val(),
              }
            }
            console.log(put_file);
            this.policy_putMessageAttachedfile(put_file,this.internalControl_id,$fileId);
          } 
        }
      }
      console.log(this.internalControl_attached_file);
      message
        .update(this.editmessage, id)
        .then(
          (response) => {
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(id);
            }

            this.putMessageMeta(this.cs_message, id, this.cs_id);
            if (this.cs_attached_file.length > 0) {
              this.cs_postMessageAttachedfile(this.cs_id);
            }

            this.putMessageMeta(this.areaService_message, id, this.areaService_id);
            if (this.areaService_attached_file.length > 0) {
              this.areaService_postMessageAttachedfile(this.areaService_id);
            }

            this.putMessageMeta(this.environmentService_message, id, this.environmentService_id);
            if (this.environmentService_attached_file.length > 0) {
              this.environmentService_postMessageAttachedfile(this.environmentService_id);
            }

            this.putMessageMeta(this.recycling_message, id, this.recycling_id);
            if (this.recycling_attached_file.length > 0) {
              this.recycling_postMessageAttachedfile(this.recycling_id);
            }

            this.putMessageMeta(this.control_message, id, this.control_id);
            if (this.control_attached_file.length > 0) {
              this.control_postMessageAttachedfile(this.control_id);
            }

            this.putMessageMeta(this.internalControl_message, id, this.internalControl_id);
            if (this.internalControl_attached_file.length > 0) {
              this.internalControl_postMessageAttachedfile(this.internalControl_id);
            }

            if (this.delete_attached_file.length > 0) {
              this.delete_attached_file.forEach(file_id => {
                this.deleteAttachedfile(id,file_id);
              });
            }
            if (this.cs_delete_attached_file.length > 0) {
              this.cs_delete_attached_file.forEach(file_id => {
                this.policy_deleteAttachedfile(this.cs_id,file_id);
              });
            }
            if (this.areaService_delete_attached_file.length > 0) {
              this.areaService_delete_attached_file.forEach(file_id => {
                this.policy_deleteAttachedfile(this.areaService_id,file_id);
              });
            }
            if (this.environmentService_delete_attached_file.length > 0) {
              this.environmentService_delete_attached_file.forEach(file_id => {
                this.policy_deleteAttachedfile(this.environmentService_id,file_id);
              });
            }
            if (this.recycling_delete_attached_file.length > 0) {
              this.recycling_delete_attached_file.forEach(file_id => {
                this.policy_deleteAttachedfile(this.recycling_id,file_id);
              });
            }
            if (this.control_delete_attached_file.length > 0) {
              this.control_delete_attached_file.forEach(file_id => {
                this.policy_deleteAttachedfile(this.control_id,file_id);
              });
            }
            if (this.internalControl_delete_attached_file.length > 0) {
              this.internalControl_delete_attached_file.forEach(file_id => {
                this.policy_deleteAttachedfile(this.internalControl_id,file_id);
              });
            }
            this.$router.push({name: "Page Policy Show"});
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.loader = false;
          }
        )
        .catch(() => {})
        .finally(() => {});
    },

    async postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.attached_file.length; i++) {
        await message.register_file(this.attached_file[i], createdId)
          .then(
            (response) => {
              if (i == this.attached_file.length - 1) {
                if (response != "") {
                console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },
    async cs_postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.cs_attached_file.length; i++) {
        await message.register_policy_file(this.cs_attached_file[i], createdId)
        .then(
          (response) => {
            if (i == this.cs_attached_file.length - 1) {
              if (response != "") {
              console.log(response);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {})
        .finally(() => {});
      }
    },
    async areaService_postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.areaService_attached_file.length; i++) {
        await message.register_policy_file(this.areaService_attached_file[i], createdId)
        .then(
          (response) => {
            if (i == this.areaService_attached_file.length - 1) {
              if (response != "") {
              console.log(response);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {})
        .finally(() => {});
      }
    },
    async environmentService_postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.environmentService_attached_file.length; i++) {
        await message.register_policy_file(this.environmentService_attached_file[i], createdId)
        .then(
          (response) => {
            if (i == this.environmentService_attached_file.length - 1) {
              if (response != "") {
              console.log(response);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {})
        .finally(() => {});
      }
    },
    async recycling_postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.recycling_attached_file.length; i++) {
        await message.register_policy_file(this.recycling_attached_file[i], createdId)
        .then(
          (response) => {
            if (i == this.recycling_attached_file.length - 1) {
              if (response != "") {
              console.log(response);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {})
        .finally(() => {});
      }
    },
    async control_postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.control_attached_file.length; i++) {
        await message.register_policy_file(this.control_attached_file[i], createdId)
        .then(
          (response) => {
            if (i == this.control_attached_file.length - 1) {
              if (response != "") {
              console.log(response);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {})
        .finally(() => {});
      }
    },
    async internalControl_postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.internalControl_attached_file.length; i++) {
        await message.register_policy_file(this.internalControl_attached_file[i], createdId)
        .then(
          (response) => {
            if (i == this.internalControl_attached_file.length - 1) {
              if (response != "") {
              console.log(response);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {})
        .finally(() => {});
      }
    },
    putMessageAttachedfile(put_file,id,$fileId) {
    message.update_file(put_file,id,$fileId)
        .then(
        (response) => {
          console.log('meta');
          console.log(response);
          // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
        },
        (error) => {
          console.log('metaerror');
          console.log(error);
          console.log(error.response.data.errors);
          // this.$router.push({ name: 'Front Error' });
        }
        )
        .catch((error) => {
          console.log('metaerrorcatch');
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
    },
    deleteAttachedfile(id,file_id){
    message.file_delete(id,file_id)
      .then(
        (response) => {
          console.log('file');
          console.log(response);
        },
        (error) => {
          console.log('fileerror');
          console.log(error);
          console.log(error.response.data.errors);
          //this.$router.push({ name: 'Front Error' });
        }
        )
        .catch((error) => {
          console.log('fileerrorcatch');
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
    },
    policy_putMessageAttachedfile(put_file,id,$fileId) {
    message.update_policy_file(put_file,id,$fileId)
        .then(
        (response) => {
          console.log('meta');
          console.log(response);
          // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
        },
        (error) => {
          console.log('metaerror');
          console.log(error);
          console.log(error.response.data.errors);
          // this.$router.push({ name: 'Front Error' });
        }
        )
        .catch((error) => {
          console.log('metaerrorcatch');
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
    },
    policy_deleteAttachedfile(id,file_id){
    message.policy_file_delete(id,file_id)
      .then(
        (response) => {
          console.log('file');
          console.log(response);
        },
        (error) => {
          console.log('fileerror');
          console.log(error);
          console.log(error.response.data.errors);
          //this.$router.push({ name: 'Front Error' });
        }
        )
        .catch((error) => {
          console.log('fileerrorcatch');
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
    },
    delete_file(file_id,policy){
      if(policy == 'cs'){
        this.cs_delete_attached_file.push(file_id);
        console.log(this.cs_delete_attached_file);
      }else if(policy == 'areaService'){
        this.areaService_delete_attached_file.push(file_id);
        console.log(this.areaService_delete_attached_file);
      }else if(policy == 'environmentService'){
        this.environmentService_delete_attached_file.push(file_id);
        console.log(this.environmentService_delete_attached_file);
      }else if(policy == 'recycling'){
        this.recycling_delete_attached_file.push(file_id);
        console.log(this.recycling_delete_attached_file);
      }else if(policy == 'control'){
        this.control_delete_attached_file.push(file_id);
        console.log(this.control_delete_attached_file);
      }else if(policy == 'internalControl'){
        this.internalControl_delete_attached_file.push(file_id);
        console.log(this.internalControl_delete_attached_file);
      }else{
        this.delete_attached_file.push(file_id);
        console.log(this.delete_attached_file);
      }
    },
    putMessageMeta(form, message_id, meta_id) {
      message.update_meta(form,message_id,meta_id)
      .then(
      (response) => {
        console.log('meta');
        console.log(response);
        // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
      },
      (error) => {
        console.log('metaerror');
        console.log(error);
        console.log(error.response.data.errors);
        // this.$router.push({ name: 'Front Error' });
      }
      )
      .catch((error) => {
        console.log('metaerrorcatch');
        console.log(error);
        // this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm");
      }
    },

    enable: function () {
      this.processing = false;
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
